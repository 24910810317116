import { RouteNames } from "./route-names";

export const MONTHS = [
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
];

export const RESOURCE_CATEGORIES = [
  {
    name: 'Food',
    subcategories: [
      { name: 'Food Delivery' },
      { name: 'Food Pantry' },
      { name: 'Help Pay for Food' },
      { name: 'Meals' },
      { name: 'Nutrition Education' },
    ],
  },
  {
    name: 'Housing',
    subcategories: [
      { name: 'Help Find Housing' },
      { name: 'Help Pay for Housing' },
      { name: 'Housing Advice' },
      { name: 'Maintenance & Repairs' },
      { name: 'Residential Housing' },
      { name: 'Temporary Shelter' },
    ],
  },
  {
    name: 'Goods',
    subcategories: [
      { name: 'Baby Supplies' },
      { name: 'Clothing' },
      { name: 'Home Goods' },
      { name: 'Medical Supplies' },
      { name: 'Personal Safety' },
      { name: 'Toys & Gifts' },
    ],
  },
  {
    name: 'Transit',
    subcategories: [
      { name: 'Help Pay for Transit' },
      { name: 'Transportation' },
    ],
  },
  {
    name: 'Health',
    subcategories: [
      { name: 'Addiction & Recovery' },
      { name: 'Dental Care' },
      { name: 'End-of-Life Care' },
      { name: 'Health Education' },
      { name: 'Help Pay for Healthcare' },
      { name: 'Medical Care' },
      { name: 'Mental Health Care' },
      { name: 'Sexual and Reproductive Health' },
      { name: 'Vision Care' },
    ],
  },
  {
    name: 'Money',
    subcategories: [
      { name: 'Financial Assistance' },
      { name: 'Financial Education' },
      { name: 'Government Benefits' },
      { name: 'Insurance' },
      { name: 'Loans' },
      { name: 'Tax Preparation' },
    ],
  },
  {
    name: 'Care',
    subcategories: [
      { name: 'Adoption & Foster Care' },
      { name: 'Animal Welfare' },
      { name: 'Community Support Services' },
      { name: 'Daytime Care' },
      { name: 'End-of-Life Care' },
      { name: 'Navigating the System' },
      { name: 'Physical Safety' },
      { name: 'Residential Care' },
      { name: 'Support Network' },
    ],
  },
  {
    name: 'Education',
    subcategories: [
      { name: 'Help Find School' },
      { name: 'Help Pay for School' },
      { name: 'More Education' },
      { name: 'Preschool' },
      { name: 'Screening & Exams' },
      { name: 'Skills & Training' },
    ],
  },
  {
    name: 'Work',
    subcategories: [
      { name: 'Help Find Work' },
      { name: 'Help Pay for Work Expenses' },
      { name: 'Skills & Training' },
      { name: 'Supported Employment' },
      { name: 'Workplace Rights' },
    ],
  },
  {
    name: 'Legal',
    subcategories: [
      { name: 'Advocacy & Legal Aid' },
      { name: 'Mediation' },
      { name: 'Notary' },
      { name: 'Representation' },
      { name: 'Translation & Interpretation' },
    ],
  },
  {
    name: 'Other',
    subcategories: [],
  },
];

export const PROVIDER_CATEGORIES = [
  {
    name: 'Provider',
    subcategories: [
      { name: 'Cardiologist' },
      { name: 'Dentist' },
      { name: 'Dietitian' },
      { name: 'ENT' },
      { name: 'Gastroenterologist' },
      { name: 'Gynecologist' },
      { name: 'Hematologist' },
      { name: 'Immunologist' },
      { name: 'Nephrologist' },
      { name: 'Neurologist' },
      { name: 'Occupational Therapy' },
      { name: 'Oncologist' },
      { name: 'Ophthalmologist' },
      { name: 'Orthopedist' },
      { name: 'Other' },
      { name: 'PCP' },
      { name: 'Pediatrician' },
      { name: 'Physical Therapy' },
      { name: 'PM&R' },
      { name: 'Psychiatrist' },
      { name: 'Pulmonologist' },
      { name: 'Speech/Feeding Therapy' },
    ],
  },
  {
    name: 'Service Coordinator',
    subcategories: [
      { name: 'Aetna' },
      { name: 'Well Point' },
    ],
  },
  {
    name: 'LIDDA',
    subcategories: [],
  },
  {
    name: 'Community Contact',
    subcategories: [
      { name: 'ECI' },
      { name: 'Education' },
      { name: 'Financial Support' },
      { name: 'Food' },
      { name: 'Housing' },
      { name: 'Legal Support' },
      { name: 'Transportation' },
      { name: 'Utilities' }
    ],
  },
  {
    name: 'Other',
    subcategories: [],
  },
];

export const EXTERNAL_ROLES = [{
  name: 'Patient',
  defaultPage: RouteNames.events.path
}]

export const BUZZ = {
  loginUrl: 'https://buzz.skyscape.com/login'
}
export const SURVEY = {
  loginUrl: 'https://www.surveymonkey.com/login'
}
